










































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo24',
})
export default class Logo24 extends Vue {}
